export class GravarAlterarLaudo {
	id?: number;
	emitirLaudo?: boolean;
	resumoCurriculo?: string;
	analiseCurricular?: string;
	resultadoQuestionario?: string;
	sugestaoAlocacao?: string;
	comentariosAnaliseEixo?: AnaliseEixo[];
	nomeResponsavel?: string;
	cpfResponsavel?: string;
}

export class AnaliseEixo {
	id?: number;
	texto?: string;
}
