import { ActivatedRoute, Router } from '@angular/router';
import { AlterarConclusaoLaudo, ComentariosAnaliseEixo, EixoAvaliacao } from '@app/core/models/perfil-profissiografico/alterar-laudo';
import { Component, OnInit } from '@angular/core';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { Subscription, catchError, tap } from 'rxjs';

import { AnaliseEixo } from './../../core/models/perfil-profissiografico/gravar-alterar-laudo';
import { GravarAlterarLaudo } from '@app/core/models/perfil-profissiografico/gravar-alterar-laudo';
import { LaudoService } from '../services/laudo.service';
import { Location } from '@angular/common';
import { PerfilProfissiograficoApiService } from '@app/core/services/perfil-profissiografico-api.service';
import { UiService } from '@app/core/services/ui.service';
import { UsuarioService } from '@sigepe/sigepe-template';

@Component({
	selector: 'sgp-alterar-laudo',
	templateUrl: './alterar-laudo.component.html'
})
export class AlterarLaudoComponent implements OnInit {
	subscriptions: Subscription[] = [];
	mensagemFeedback: Message[] = [];
	// showModalObservacao = false;
	loading = false;
	erroResumoNaoPreenchido = false;
	erroAnaliseCurricularNaoPreenchida = false;
	erroResultadoQuestionarioNaoPreenchido = false;
	erroSugestaoAlocacaoNaoPreenchida = false;
	// erroObservacaoEixoNaoPreenchida = false;
	desabilitar = false;
	analiseCurricular?: string;
	resultadoQuestionario?: string;
	sugestaoAlocacao?: string;
	eixoNaoPreenchido: boolean[] = [];
	origemPesquisarLaudo?: boolean;
	idCandidato?: number;
	// campoObservacao?: string;
	laudo?: GravarAlterarLaudo;
	selecionado?: AlterarConclusaoLaudo = new AlterarConclusaoLaudo();
	emitido = false;
	nomeUsuario?: string;
	cpfUsuario?: string;

	constructor(
		protected laudoService: LaudoService,
		// private messageService: MessageService,
		private router: Router,
		private ui: UiService,
		private confirmationService: ConfirmationService,
		private route: ActivatedRoute,
		private apiService: PerfilProfissiograficoApiService,
		private location: Location,
		private usuarioService: UsuarioService
	) {}

	ngOnInit(): void {
		this.obterParametros();
		this.obterOrigem();
		this.consultar();
	}

	private obterParametros() {
		this.idCandidato = Number(this.route.snapshot.paramMap.get('id'));
		this.nomeUsuario = this.usuarioService.getToken().name;
		this.cpfUsuario = this.usuarioService.getToken().sub;
	}

	consultar() {
		this.laudoService.pesquisar(this.idCandidato!);
		this.laudoService.resultado$?.subscribe({
			next: res => {
				this.selecionado = res;
				// console.log(this.selecionado);
			}
		});
	}

	validar() {
		this.erroResumoNaoPreenchido = false;
		this.erroAnaliseCurricularNaoPreenchida = false;
		this.erroResultadoQuestionarioNaoPreenchido = false;
		this.erroSugestaoAlocacaoNaoPreenchida = false;
		let hap1 = false;

		if (this.selecionado?.resumoCurriculo?.length == 0) {
			this.erroResumoNaoPreenchido = true;
			hap1 = true;
		}
		if (this.selecionado?.analiseCurricular?.length == 0) {
			this.erroAnaliseCurricularNaoPreenchida = true;
			hap1 = true;
		}
		if (this.selecionado?.resultadoQuestionario?.length == 0) {
			this.erroResultadoQuestionarioNaoPreenchido = true;
			hap1 = true;
		}
		if (this.selecionado?.sugestaoAlocacao?.length == 0) {
			this.erroSugestaoAlocacaoNaoPreenchida = true;
			hap1 = true;
		}

		if (this.selecionado!.comentariosAnaliseEixo!.length > 0) {
			for (let i = 0; i < this.selecionado!.comentariosAnaliseEixo!.length; i++) {
				this.selecionado!.comentariosAnaliseEixo![i].naoPreenchido = false;
			}
			for (let i = 0; i < this.selecionado!.comentariosAnaliseEixo!.length; i++) {
				if (this.selecionado!.comentariosAnaliseEixo![i].texto!.length <= 0) {
					this.selecionado!.comentariosAnaliseEixo![i].naoPreenchido = true;
					hap1 = true;
				}
			}
		}

		return !hap1;
	}

	alterar() {
		if (this.validar()) {
			const laudo: GravarAlterarLaudo = this.montarGravarAlterarLaudo(false);
			this.subscriptions.push(
				this.apiService
					.alterarLaudo(laudo)
					.pipe(
						tap(() => {
							this.mensagemFeedback = this.ui.criarListaMensagem('success', '', 'Relatório gravado com sucesso');
							return [this.mensagemFeedback];
						}),
						catchError(err => {
							this.mensagemFeedback = this.ui.criarListaMensagem('error', '', err.error.message);
							return [this.mensagemFeedback];
						})
					)
					.subscribe()
			);
		}
	}

	emitir() {
		if (this.validar()) {
			const laudo: GravarAlterarLaudo = this.montarGravarAlterarLaudo(true);
			this.confirmationService.confirm({
				message: 'Esta ação não poderá ser desfeita.',
				header: 'Deseja Emitir o Relatório?',
				accept: async () => {
					this.subscriptions.push(
						this.apiService
							.alterarLaudo(laudo)
							.pipe(
								tap(() => {
									this.emitido = true;
									this.mensagemFeedback = this.ui.criarListaMensagem('success', '', 'Relatório emitido com sucesso');
									return [this.mensagemFeedback];
								}),
								catchError(err => {
									this.mensagemFeedback = this.ui.criarListaMensagem('error', '', err.error.message);
									return [this.mensagemFeedback];
								})
							)
							.subscribe()
					);
				}
			});
		}
	}
	private montarGravarAlterarLaudo(emitir: boolean): GravarAlterarLaudo {
		let novo: GravarAlterarLaudo = new GravarAlterarLaudo();
		novo.id = this.idCandidato;
		novo.emitirLaudo = emitir;
		novo.resumoCurriculo = this.selecionado?.resumoCurriculo;
		novo.analiseCurricular = this.selecionado?.analiseCurricular;
		novo.resultadoQuestionario = this.selecionado?.resultadoQuestionario;
		novo.sugestaoAlocacao = this.selecionado?.sugestaoAlocacao;
		novo.comentariosAnaliseEixo = [];
		if (this.selecionado!.comentariosAnaliseEixo!.length > 0) {
			for (let i = 0; i < this.selecionado!.comentariosAnaliseEixo!.length; i++) {
				const item = this.selecionado!.comentariosAnaliseEixo![i];
				let ii: AnaliseEixo = new AnaliseEixo();
				ii.id = item.id;
				ii.texto = item.texto;
				novo.comentariosAnaliseEixo.push(ii);
			}
		}
		novo.nomeResponsavel = this.nomeUsuario;
		novo.cpfResponsavel = this.cpfUsuario;
		return novo;
	}

	voltar() {
		if (this.origemPesquisarLaudo) {
			this.router.navigate(['perfilProfissiografico/pesquisarLaudo'], 
				{ queryParams: { preservarParametros: true }
			});
			return;
		}
		this.location.back();
	}

	removerNaoPreenchido() {
		this.erroAnaliseCurricularNaoPreenchida = false;
		this.erroResultadoQuestionarioNaoPreenchido = false;
		this.erroSugestaoAlocacaoNaoPreenchida = false;
		if (this.selecionado!.comentariosAnaliseEixo!.length > 0) {
			for (let i = 0; i < this.selecionado!.comentariosAnaliseEixo!.length; i++) {
				this.selecionado!.comentariosAnaliseEixo![i].naoPreenchido = false;
			}
		}
	}

	private obterOrigem() {
		const navigation = this.router.getCurrentNavigation() ?? this.router.lastSuccessfulNavigation;
		this.origemPesquisarLaudo = navigation?.extras?.state?.origem == 'pesquisarLaudo' ? true : false;
	}
}
