<p-messages [(value)]="mensagemFeedback" [enableService]="false" [closable]="false"></p-messages>

<h3 data-cy="titulo-pagina">Alterar relatório do candidato</h3>

<div *ngIf="(laudoService.resultado$ | async) as resultado">
	<div class="row">
		<div class="col-sm-12">
			<div class="br-card bg-gray-2">
				<div class="card-content">
					<p-accordion [activeIndex]="0">
						<p-accordionTab header="Resumo do Perfil do Candidato">
							<div class="row">
								<div class="col-sm-6 mt-2 br-input">
									<label for="resumo" class="text-up-01">Resumo do Currículo:</label>
									<textarea
										id="resumo"
										[(ngModel)]="selecionado!.resumoCurriculo"
										rows="12"
										cols="90"
										data-cy="campo-resumo-curriculo"
										[disabled]="desabilitar"
										maxlength="4000"
										(input)="removerNaoPreenchido()"
									></textarea>
									<sgp-message-feedback
										[show]="erroResumoNaoPreenchido"
										mensagem="Campo obrigatório"
									></sgp-message-feedback>
								</div>
							</div>
						</p-accordionTab>
					</p-accordion>
				</div>
			</div>
			<div class="br-card bg-gray-2">
				<div class="card-content">
					<p-accordion [activeIndex]="0">
						<p-accordionTab header="Conclusão do Relatório">
							<div class="row">
								<div class="col-sm-6 mt-2 br-input">
									<label for="curricular" class="text-up-01">Análise Curricular:</label>
									<textarea
										id="curricular"
										[(ngModel)]="selecionado!.analiseCurricular"
										rows="12"
										cols="90"
										data-cy="campo-analise-curricular"
										[disabled]="desabilitar"
										maxlength="4000"
										(input)="removerNaoPreenchido()"
									></textarea>
									<sgp-message-feedback
										[show]="erroAnaliseCurricularNaoPreenchida"
										mensagem="Campo obrigatório"
									></sgp-message-feedback>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-6 mt-2 br-input">
									<label for="questionario" class="text-up-01">Resultado do Questionário</label>
									<textarea
										id="questionario"
										[(ngModel)]="selecionado!.resultadoQuestionario"
										rows="12"
										cols="90"
										data-cy="campo-resultado-questionario"
										[disabled]="desabilitar"
										maxlength="4000"
										(input)="removerNaoPreenchido()"
									></textarea>
									<sgp-message-feedback
										[show]="erroResultadoQuestionarioNaoPreenchido"
										mensagem="Campo obrigatório"
									></sgp-message-feedback>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-6 mt-2 br-input">
									<label for="alocacao" class="text-up-01">Sugestão de Alocação:</label>
									<textarea
										id="alocacao"
										[(ngModel)]="selecionado!.sugestaoAlocacao"
										rows="12"
										cols="90"
										data-cy="campo-sugestao-alocacao"
										[disabled]="desabilitar"
										maxlength="4000"
										(input)="removerNaoPreenchido()"
									></textarea>
									<sgp-message-feedback
										[show]="erroSugestaoAlocacaoNaoPreenchida"
										mensagem="Campo obrigatório"
									></sgp-message-feedback>
								</div>
							</div>
						</p-accordionTab>
					</p-accordion>
				</div>
			</div>
			<div class="br-card bg-gray-2">
				<div class="card-content">
					<div class="row">
						<div class="col-sm-12 mt-2" *ngIf="selecionado!.comentariosAnaliseEixo!.length > 0">
							<p-accordion [activeIndex]="0">
								<p-accordionTab [header]="tab.eixoAvaliacao!.nome" *ngFor="let tab of selecionado!.comentariosAnaliseEixo">
									<textarea
										id="eixo_{{tab.id}}"
										[(ngModel)]="tab.texto"
										rows="12"
										cols="90"
										data-cy="campo-eixo-{{tab.id}}"
										maxlength="4000"
										(input)="removerNaoPreenchido()"
									></textarea>
									<sgp-message-feedback [show]="tab.naoPreenchido" mensagem="Campo obrigatório"></sgp-message-feedback>
								</p-accordionTab>
							</p-accordion>
						</div>
					</div>
				</div>
			</div>
			<div class="card-footer">
				<div class="row mt-4">
					<div class="col text-left mt-2">
						<button (click)="voltar()" *ngIf="!emitido">Voltar</button>
						<button (click)="voltar()" *ngIf="emitido">Fechar</button>
					</div>
					<div class="col text-right">
						<p-button label="Alterar" (click)="alterar()" styleClass="br-button secondary" *ngIf="!emitido"></p-button>
						<p-button label="Emitir" (click)="emitir()" styleClass="br-button primary ml-2" *ngIf="!emitido"></p-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<p-confirmDialog acceptLabel="Confirmar" rejectLabel="Cancelar"></p-confirmDialog>
