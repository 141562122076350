import { AlterarConclusaoLaudo, ComentariosAnaliseEixo } from '@app/core/models/perfil-profissiografico/alterar-laudo';
import { Observable, Subscription, catchError, of } from 'rxjs';

import { GravarAlterarLaudo } from '@app/core/models/perfil-profissiografico/gravar-alterar-laudo';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { PerfilProfissiograficoApiService } from '@app/core/services/perfil-profissiografico-api.service';

@Injectable({
	providedIn: 'any'
})
export class LaudoService {
	resultado$?: Observable<AlterarConclusaoLaudo>;
	subscriptions: Subscription[] = [];
	constructor(private apiService: PerfilProfissiograficoApiService, private messageService: MessageService) {}

	pesquisar(id: number): void {
		this.resultado$ = this.apiService.consultarConclusaoLaudo(id).pipe(
			catchError(error => {
				this.handleError(error, 'executar a pesquisa');
				throw error;
			})
		);
		// this.resultado$ = mockConclusaoLaudoPesquisa();
	}
	alterarLaudo(laudo: GravarAlterarLaudo) {
		return this.apiService.alterarLaudo(laudo);
	}

	private handleError(error: any, descricao: string) {
		this.messageService.add({
			severity: 'error',
			summary: `Erro ao ${descricao}. `,
			detail: ' Tente novamente mais tarde.'
		});
	}
}
function mockConclusaoLaudoPesquisa(): Observable<AlterarConclusaoLaudo> {
	let acl = new AlterarConclusaoLaudo();
	acl.id = 1;
	acl.resumoCurriculo = 'texto de resumo do curriculo alvo';
	acl.analiseCurricular = 'texto de análise curricular alvo';
	acl.resultadoQuestionario = 'texto de resultado de questionário alvo';
	acl.sugestaoAlocacao = 'texto de sugestão de alocação alvo';
	acl.comentariosAnaliseEixo = [];
	let c1 = new ComentariosAnaliseEixo();
	c1.id = 1;
	c1.texto = 'texto 1';
	c1.eixoAvaliacao = {
		id: 1,
		nome: 'conjunto 1'
	};
	let c2 = new ComentariosAnaliseEixo();
	c2.id = 2;
	c2.texto = 'texto 112';
	c2.eixoAvaliacao = {
		id: 2,
		nome: 'conjunto 2'
	};
	acl.comentariosAnaliseEixo.push(c1);
	acl.comentariosAnaliseEixo.push(c2);
	return of(acl);
}
